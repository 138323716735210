import { Component, OnInit, Input } from '@angular/core';
import { of, EMPTY } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DatalayerService } from 'src/services/datalayer.service';
import { Important } from '../CPSMessages.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { DocumentService } from 'src/services/document.service';

@Component({
  selector: 'app-cps-important-messages',
  templateUrl: './cps-important-messages.component.html',
  styleUrls: ['./cps-important-messages.component.scss']
})
export class CpsImportantMessagesComponent implements OnInit {

  @Input() importantMsgs: Important[];

  constructor(private documentService: DocumentService, private datalayerService: DatalayerService, private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.datalayerService.bannerImpressionsEvent('Important Messages', 'monograph_message_centre');
    }, 1000);
  }
  
  clickEvent(fileName: string, token: string, event: MouseEvent): void {
    const element = event.target as HTMLElement;
    const rect = element.getBoundingClientRect();
    const position = rect.top + window.scrollY;
    const fileExtension = fileName.split('.').pop();

    this.documentService.getFile(fileName, token).subscribe(
      (result) => {
        const file = URL.createObjectURL(result);
        window.open(file, '_blank');
        this.datalayerService.fileDownloadEvent(fileExtension, fileName, position);
        this.datalayerService.bannerClickEvent('Important Product Information', 'monograph_message_centre', fileName );
      },
      catchError(() => EMPTY)
    );
  }
}
